import {
    AlarmClockAppImage,
    BooksReaderAppImage,
    CamonAppImage,
    TapBotAppImage,
    QuestionImage,
    OrangeCircleIllustration,
    ClockIllustration,
    RocketIllustration,
    ZebraCircleIllustration,
    BlackCircleIllustration,
    BlueCircleIllustration,
    CursorIllustration,
    BookIllustration,
    DoubleCirclesIllustration,
    PinkCircleIllustration,
    Gallery1Image1,
    Gallery1Image2,
    Gallery1Image3,
    Gallery1Image4,
    Gallery1Image5,
    Gallery1Image6,
    Gallery1Image7,
    Gallery4Image7,
    Gallery2Image1,
    Gallery2Image2,
    Gallery2Image3,
    Gallery2Image4,
    Gallery3Image1,
    Gallery3Image2,
    Gallery3Image3,
    Gallery3Image4,
    Gallery4Image1,
    Gallery4Image2,
    Gallery4Image3,
    Gallery4Image4,
    Gallery4Image5,
    Gallery4Image6,
    Gallery3Image5,
    Gallery3Image6,
    Gallery3Image7,
    Gallery3Image8,
    Gallery3Image9,
    Gallery4Image8,
    Gallery4Image9,
} from 'assets';
import { APPS_MENU_ITEM, ABOUT_MENU_ITEM, CONTACT_MENU_ITEM, PRIVACY_MENU_ITEM, TERMS_MENU_ITEM } from 'locales';
import { AppCardData, NavMenuItem } from 'types/App.types';

// MENU LINKS
export enum Topics {
    ALARM_CLOCK_MENU_ITEM = 'Alarm Clock',
    CAM_ON_MENU_ITEM = 'Cam.On',
    AUTOCLICKER_MENU_ITEM = 'Tap Bot',
    BOOKS_READER_MENU_ITEM = 'Books Reader',
    COMMON_QUESTIONS_ITEM = 'Common Questions',
}

export const APPS_MENU_LINK = '#our-apps';
export const ABOUT_MENU_LINK = '#about-us';
export const CONTACT_MENU_LINK = '#contact-us';
export const PRIVACY_MENU_LINK = 'policy';
export const TERMS_MENU_LINK = 'terms';
export const ID_STARTING_INDEX = 1;

export const ALARM_CLOCK_MENU_LINK = '#alarm-clock';
export const CAM_ON_MENU_LINK = '#cam-on';
export const AUTOCLICKER_MENU_LINK = '#autoclicker';
export const BOOKS_READER_MENU_LINK = '#books-reader';

// MENU
export const FOOTER_MENU_ITEMS: NavMenuItem[] = [
    {
        title: APPS_MENU_ITEM,
        link: APPS_MENU_LINK,
    },
    {
        title: ABOUT_MENU_ITEM,
        link: ABOUT_MENU_LINK,
    },
    {
        title: CONTACT_MENU_ITEM,
        link: CONTACT_MENU_LINK,
    },
    {
        title: PRIVACY_MENU_ITEM,
        link: PRIVACY_MENU_LINK,
    },
    {
        title: TERMS_MENU_ITEM,
        link: TERMS_MENU_LINK,
    },
];

export const HEADER_MENU_ITEMS: NavMenuItem[] = [
    {
        title: APPS_MENU_ITEM,
        link: APPS_MENU_LINK,
        subMenuItems: [
            {
                title: Topics.ALARM_CLOCK_MENU_ITEM,
                link: ALARM_CLOCK_MENU_LINK,
                icon: AlarmClockAppImage,
            },
            {
                title: Topics.CAM_ON_MENU_ITEM,
                link: CAM_ON_MENU_LINK,
                icon: CamonAppImage,
            },
            {
                title: Topics.AUTOCLICKER_MENU_ITEM,
                link: AUTOCLICKER_MENU_LINK,
                icon: TapBotAppImage,
            },
            {
                title: Topics.BOOKS_READER_MENU_ITEM,
                link: BOOKS_READER_MENU_LINK,
                icon: BooksReaderAppImage,
            },
        ],
    },
    {
        title: ABOUT_MENU_ITEM,
        link: ABOUT_MENU_LINK,
    },
    {
        title: CONTACT_MENU_ITEM,
        link: CONTACT_MENU_LINK,
    },
];

// GALLERY
export const ALARM_CLOCK_GALLERY_DATA: string[] = [
    Gallery1Image1,
    Gallery1Image2,
    Gallery1Image3,
    Gallery1Image4,
    Gallery1Image5,
    Gallery1Image6,
    Gallery1Image7,
];

export const CAM_ON_GALLERY_DATA: string[] = [
    Gallery3Image1,
    Gallery3Image2,
    Gallery3Image3,
    Gallery3Image4,
    Gallery3Image5,
    Gallery3Image6,
    Gallery3Image7,
    Gallery3Image8,
    Gallery3Image9,
];

export const TAP_BOT_GALLERY_DATA: string[] = [
    Gallery4Image1,
    Gallery4Image2,
    Gallery4Image3,
    Gallery4Image4,
    Gallery4Image5,
    Gallery4Image6,
    Gallery4Image7,
    Gallery4Image8,
    Gallery4Image9,
];

export const BOOKS_READER_GALLERY_DATA: string[] = [Gallery2Image1, Gallery2Image2, Gallery2Image3, Gallery2Image4];

// CARDS
export const APPS_DATA: AppCardData[] = [
    {
        icon: AlarmClockAppImage,
        title: 'Alarm Clock:',
        subtitle: 'Wake Up On Time',
        shortDescription: 'Desktop Alert Widget: No Sleep',
        description:
            'Waking up at the right time is a headache for many people. Our alarm clock for Mac is here to help. Many settings will help you customize it to your needs: set up a schedule, use a widget, select Fade mode for a soft wake-up, add text to get a reminder, set your own ringtone or choose from ours, and much more. This alarm clock for Mac it will definitely not let you down, even if the sound on your computer was turned off.',
        macAppStoreLink: 'https://apps.apple.com/us/app/id1615542893',
        sectionLink: ALARM_CLOCK_MENU_LINK,
        images: ALARM_CLOCK_GALLERY_DATA,
        style: 'light',
        illustrations: [OrangeCircleIllustration, ClockIllustration],
        type: 'card1',
    },
    {
        icon: CamonAppImage,
        title: 'Cam.On: ',
        subtitle: 'Webcam Video Recorder',
        shortDescription: 'Camera App: Settings, Effects',
        description:
            'Record videos, your screen or create reaction videos with our webcam app for Mac. With Cam.On you can edit recorded videos, mirror the image, add filters, use «picture-in-picture» mode, choose the size and quality of your video and save it in desired format. This user friendly webcam app for Mac will beсome your best helper when you need to make a video.',
        macAppStoreLink: 'https://apps.apple.com/us/app/id1638061186',
        sectionLink: CAM_ON_MENU_LINK,
        images: CAM_ON_GALLERY_DATA,
        style: 'dark',
        illustrations: [RocketIllustration, ZebraCircleIllustration, BlackCircleIllustration],
        type: 'card2',
    },
    {
        icon: TapBotAppImage,
        title: 'Tap Bot:',
        subtitle: 'Auto Click & Refresh',
        shortDescription: 'Mouse Clicker & Key Pressor',
        description:
            'Tap Autoclicker is amazing auto clicker for Mac. It is indispensable for use in games and a number of routine tasks where you are required to regularly click, scroll or press the same key combinations. Here are just a few features of Tap Autoclicker: ability to set needed time between clicks, delayed start, possibility to create and use macros and click with both right and left mouse buttons. Usage of auto clicker for Mac will save your time and energy.',
        macAppStoreLink: 'https://apps.apple.com/us/app/id6444782835',
        images: TAP_BOT_GALLERY_DATA,
        sectionLink: AUTOCLICKER_MENU_LINK,
        style: 'light',
        illustrations: [BlueCircleIllustration, CursorIllustration],
        type: 'card3',
    },
    {
        icon: BooksReaderAppImage,
        title: 'Books Reader - ',
        subtitle: 'Pocket Library',
        shortDescription: 'Reading tracker & open ebooks',
        description:
            'If you love to read, then this free books reader app for iPhone and iPad will be indispensable for you! Books Reader - Pocket Library works with the most popular e-book formats, allows you to adjust brightness, font, size, and use bookmarks. Enjoy using this free books reader app for iPhone and its features: auto unpacking of zip archives, marking pages and taking notes, convenient filtering system and much more.',
        appStoreLink: 'https://apps.apple.com/us/app/id1550557954',
        sectionLink: BOOKS_READER_MENU_LINK,
        images: BOOKS_READER_GALLERY_DATA,
        style: 'dark',
        isCarouselVertical: true,
        illustrations: [BookIllustration, DoubleCirclesIllustration, PinkCircleIllustration],
        type: 'card4',
    },
];

// FORM
export const ACTION_LINK = 'https://echo.free.beeceptor.com/';

export const FORM_BUTTONS = [
    {
        icon: AlarmClockAppImage,
        name: Topics.ALARM_CLOCK_MENU_ITEM,
    },
    {
        icon: CamonAppImage,
        name: Topics.CAM_ON_MENU_ITEM,
    },
    {
        icon: TapBotAppImage,
        name: Topics.AUTOCLICKER_MENU_ITEM,
    },
    {
        icon: BooksReaderAppImage,
        name: Topics.BOOKS_READER_MENU_ITEM,
    },
    {
        icon: QuestionImage,
        name: Topics.COMMON_QUESTIONS_ITEM,
    },
];

export const IP_LOCATOR_SERVICE = 'https://api.country.is';

// APP
export const WELCOME_SCREEN_CLASS = 'welcome';
export const HEADER_IDENTIFIER = 'header';
